<script>
import VillageCard from '@/components/VillageCard'
// import AboutTitle from '@/components/AboutTitle'

export default {
  name: 'VideoFon',
  components: {
    VillageCard,
    // AboutTitle,
  },
  mounted () {
  },
}
</script>

<template>
  <video poster="../assets/video/img-for-video-fon.png" video autoplay loop muted playsinline id="myVideo">
    <source src="../assets/video/bg_video_fon_mobile.mp4" type="video/mp4">
  </video>
  <!--  <img src="../assets/video/IMG_1994%20(online-video-cutter.com)%20(1).gif">-->



<!--  <AboutTitle/>-->
  <VillageCard/>

</template>

<style scoped>


video {
  object-fit: cover;
}

/* Style the video: 100% width and height to cover the entire window */
#myVideo {
  /*position: fixed;*/
  /*right: 0;*/
  /*bottom: 0;*/
  width: 100%;
  /*min-height: 500px;*/
  /*background-position: center center;*/
}

-webkit-media-controls-panel {

  display: none !important;

  -webkit-appearance: none;

}

--webkit-media-controls-play-button {

  display: none !important;

  -webkit-appearance: none;

}

-webkit-media-controls-start-playback-button {

  display: none !important;

  -webkit-appearance: none;

}

/* Add some content at the bottom of the video/page */
.content {
  position: fixed;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  width: 100vh;
  padding: 20px;
}

/* Style the button used to pause/play the video */
#myBtn {
  width: 200px;
  font-size: 18px;
  padding: 10px;
  border: none;
  background: #000;
  color: #fff;
  cursor: pointer;
}

#myBtn:hover {
  background: #ddd;
  color: black;
}


</style>
